.orders__navigation {
  display: flex;
  align-items: center;
  gap: 40px;

  & .page-title {
    padding: 16px;
    transition: all .3s ease;
    cursor: pointer;

    &.active {
      background-color: white;
      border-radius: 10px;
      box-shadow: 2px 2px 10px rgba($color: black, $alpha: .2);
    }

    &:hover {
      color: var(--official-color);

      &.active {
        background-color: darken($color: white, $amount: 3);
      }
    }
  }
}

.section__orders {
  & .section__header {
    display: flex;

    & .title {
      padding: 0px 25px 0px 10px;
      font-size: 20px;
      color: #636363;
      border-right: 2px solid #d9d9d9;
    }

    & .filter select {
      min-width: 110px;
    }
  }

  & .section__content {

    & .table tbody tr,
    & .table thead tr {
      & th:first-child {
        text-indent: 30px;
      }

      & th,
      & td {
        &:first-child {
          width: 250px !important;
          text-align: left;
        }
      }

      & .status-btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        & span.icon {
          display: block;
          width: 30px;
          height: 30px;

          & img {
            display: block;
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    & .table tbody tr {
      background-color: #fff;

      td:first-child span:first-child {
        width: 12px;
      }

      td:first-child span.dot {
        height: 12px;
        background-color: #4762E6;
        border-radius: 50%;
        display: inline-block;
      }

      td:nth-child(2) {
        border-left: none;
      }

      &.status-ordered {
        background-color: #f7f7f7;
      }

      & .status-cell {
        & span {
          background-color: #f2f2f2;
          padding: 6px 8px;
          border-radius: 6px;
        }

        & span.status-canceled {
          color: #ed4c5c;
          background-color: #fdedef;
        }

        & img {
          margin-right: 5px;
          margin-bottom: 3px;
        }

        & .canceled-btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
      }

      & td.rescheduled {
        & button {
          padding: 3px 6px;
          cursor: pointer;
        }
      }

      & td.left-to-pay {
        background-color: #ffe6e6;
      }

      & td.shipping-service-name {
        text-transform: capitalize;
      }

      & .data-not-found-cell {
        background-color: #fff;
        height: 200px;
        border: 0;

        & h2 {
          color: #666;
        }
      }
    }
  }
}

.section__createOrder {
  & .section__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .title {
      padding: 0px 25px 0px 10px;
      font-size: 20px;
      color: #636363;
      border-right: 2px solid #d9d9d9;
    }

    & .right__side {
      display: flex;
      align-items: center;
      gap: 10px;
      color: black;
      padding: 0px 10px 0px 25px;

      & .number__row {
        display: flex;
        align-items: center;
        gap: 10px;

        & .number {
          background-color: #e6e6e6;
          border: 1px solid lighten($color: gray, $amount: 30);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 2px;
          min-width: 20px;
          min-height: 20px;
          font-size: 12px;
        }

        & .link {
          font-weight: 400;
          color: black;
          cursor: pointer;
        }
      }
    }
  }

  & .create__order {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    color: black;

    & .create__table {
      padding: 30px;
      padding-right: 0px;

      @media (min-width:1800px) {
        flex: 2;
      }

      & .shipment-rates {
        border: 1px solid lightgray;
        border-top-left-radius: 13px;
        border-top-right-radius: 13px;
        overflow: hidden;
        padding: 15px 25px;
        margin-top: 20px;

        &.error {
          border-color: red;
        }

        & .your-service {
          & .form-group {
            & .your-address {
              width: 100%;
              display: flex;
              align-items: center;
              gap: 6px;

              & .address-value {
                height: 40px;
                display: flex;
                align-items: center;
                flex: 1;
                border: 1px solid #D9D9D9;
                border-radius: 4px;
                padding-inline: 10px;

                & p {
                  margin: 0;
                }
              }
            }

            & .delivery-services {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 10px;
              width: 100%;
              margin: 10px 0px;
            }
          }
        }

        & .form-group {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
        }

        & select {
          flex: 1;
          height: 40px;
          outline: none;
          border-radius: 4px;
          border: 1px solid #D9D9D9;
          padding-inline: 10px 25px;
          appearance: none;
          background: url('../../../public/assets/icons/arrow-dark.svg');
          background-repeat: no-repeat;
          background-position: 98% center;
          background-size: 14px;


          &.isError {
            border-color: red;
          }
        }
      }

      & .rest-cart-footer {
        background-color: $light5;
        border: 1px solid lightgray;
        border-top: none;
        padding: 15px 25px;
        border-bottom-left-radius: 13px;
        border-bottom-right-radius: 13px;

        &>:nth-child(1) {
          justify-content: flex-end;
        }

        & button {
          padding: 0px 20px;
        }
      }

      & .select-address-wrapper {
        padding: 12px 0px;

        & .form-group {
          position: relative;

          &>label {
            font-size: 16px;
            font-weight: normal;
            display: block;
            color: inherit;
            margin-bottom: 0.5em;
          }

          &>select {
            height: 40px;
            outline: none;
            border-radius: 4px;
            border: 1px solid #D9D9D9;
            padding-inline: 10px 25px;
            appearance: none;
            background: url('../../../public/assets/icons/arrow-dark.svg');
            background-repeat: no-repeat;
            background-position: 99% center;
            background-size: 14px;
            display: block;
            width: 100%;
          }
        }
      }

      & .search {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        height: 50px;

        &-form {
          display: grid;
          grid-template-columns: 1fr auto;
          height: 50px;
          flex: 1;

          & .form-group {
            position: relative;
            display: grid;
            grid-template-columns: 50px 1fr 50px;

            &:hover {

              & .input,
              & .icon {
                border-color: $blue40;
              }
            }

            & .input {
              border: 0;
              width: 100%;
              height: 100%;
              padding-left: 10px;
              padding-right: 10px;
              border-top: 1px solid #d9d9d9;
              border-bottom: 1px solid #d9d9d9;
              transition: border-color 0.3s ease;
              color: $dark70;

              &:focus,
              &:active {
                box-shadow: none;
              }
            }

            & .icon {
              width: 50px;
              height: 50px;
              z-index: 10;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $white;
              transition: border-color 0.3s ease;

              &-search {
                border-top: 1px solid #d9d9d9;
                border-bottom: 1px solid #d9d9d9;
                border-left: 1px solid #d9d9d9;
                border-radius: 5px 0 0 5px;
              }

              &-close {
                border-top: 1px solid #d9d9d9;
                border-bottom: 1px solid #d9d9d9;
                border-right: 1px solid transparent;
                cursor: pointer;

                &.isActive {
                  img {
                    opacity: 1;
                  }
                }

                & img {
                  opacity: 0;
                  padding: 5px;
                  width: 24px !important;
                  background-color: #d9d9d9;
                  border-radius: 50%;
                  transition: opacity 0.3s ease;
                }
              }

              & img {
                width: 16px;
              }
            }
          }

          & .form-submit {
            border: 0;
            background-color: $white;
            color: $blue40;
            width: 100px;
            border: 1px solid #d9d9d9;
            border-radius: 0 5px 5px 0;
            font-weight: 500;
            cursor: pointer;
          }
        }
      }

      & .table__container {
        margin-top: 20px;
        border: 1px solid #D9D9D9;
        border-radius: 13px;
        overflow: hidden;
      }

      & .table {
        width: 100%;

        & thead {

          & tr {
            border-bottom: 1px solid #D9D9D9;

            & th {
              padding: 20px 12px;
              text-align: left;
            }
          }
        }

        & tbody {

          & tr {
            border-bottom: 1px solid #D9D9D9;

            &>:nth-child(4) {
              max-width: 70px;
              min-width: 70px;

            }

            &>:nth-child(3) {
              word-break: break-all;
            }

            & td {
              overflow-wrap: break-word;
              white-space: normal;
              padding: 12px;
              font-size: 14px;

              & .shopIcon {
                font-size: 25px;
                border: 1px solid $dark5;
                padding: 8px;
                border-radius: 8px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }


    & .cart__order {
      padding: 30px;
      padding-left: 0;
      min-width: 550px;
      max-width: 700px;

      @media (min-width:1800px) {
        max-width: none;
        min-width: unset;
        flex: 1;
      }

      & .cart__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 50px;

        &>span {
          font-size: 20px;
        }

        & .button__container {
          height: 100%;

          & .button {
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0px 10px;
            gap: 10px;
            border-radius: 8px;
            background-color: white;
            border: 1px solid gray;
            transition: all .3s ease;
            cursor: pointer;

            &:hover {
              border-color: red;
              color: red;
              box-shadow: 0px 0px 6px rgba($color: red, $alpha: .5);
            }
          }
        }
      }

      & .cartItems {
        border: 1px solid #D9D9D9;
        padding: 20px;
        margin-top: 20px;
        max-height: 530px;
        border-radius: 13px;
        overflow-y: auto;

        & .parent {
          margin: 10px 0px;
        }

        & .product-item {
          padding: 10px 0px;
          border-bottom: 1px solid lightgray;

          & .children {

            & .product-group {

              & .product-actions {
                & .controls {
                  &>.input.input-amount {
                    width: 120px;
                    padding-inline: 10px;
                    height: 40px;
                    border-radius: 4px;
                    border: 1px solid #D9D9D9 !important;
                  }

                  & .shipping-actions>.group {
                    &>select {
                      height: 40px;
                      outline: none;
                      border-radius: 4px;
                      border: 1px solid #D9D9D9;
                      padding-inline: 10px 25px;
                      appearance: none;
                      background: url('../../../public/assets/icons/arrow-dark.svg');
                      background-repeat: no-repeat;
                      background-position: 92.5% center;
                      background-size: 14px;
                    }
                  }
                }
              }
            }
          }

          & .product-wrapper {
            display: grid;
            grid-template-columns: auto 1fr;
            column-gap: 12px;

            & .product-image {

              & .image {
                border: 1px solid lightgray;
                border-radius: 6px;
                padding: 5px;

                >img {
                  object-fit: cover;
                }
              }
            }

            & .product-group {
              display: flex;
              justify-content: space-around;
              flex-direction: column;
              gap: 12px;

              & .product-details {
                display: flex;
                justify-content: space-between;
                gap: 12px;

                & .product-attributes {
                  display: flex;
                  flex-wrap: wrap;
                  gap: 5px;

                  & .attribute {
                    display: flex;
                    padding: 8px 10px;
                    grid-gap: 7px;
                    background-color: hsl(0deg, 0%, 100%);
                    border: 1px solid #D9D9D9;
                    border-radius: 4px;

                    & .attribute-name {
                      font-size: 14px;
                      margin: 0;
                    }

                    & .attribute-unit {
                      font-size: 14px;
                      margin: 0;
                    }
                  }
                }
              }

              & .product-actions {
                & .controls {
                  display: flex;
                  flex-wrap: wrap;
                  gap: 12px;
                  justify-content: flex-end;

                  &>.input.input-amount {
                    width: 120px;
                    padding-inline: 10px;
                    height: 40px;
                    border-radius: 4px;
                    border: 1px solid #D9D9D9 !important;
                  }

                  & .shipping-actions>.group {
                    & select {
                      height: 40px;
                      outline: none;
                      border-radius: 4px;
                      border: 1px solid #D9D9D9;
                      padding-inline: 10px 25px;
                      appearance: none;
                      background: url('../../../public/assets/icons/arrow-dark.svg');
                      background-repeat: no-repeat;
                      background-position: 98% center;
                      background-size: 14px;
                    }
                  }

                  & .btn-link-info {
                    background-color: white;
                    border: 0;
                    color: $blue50;
                    font-size: 18px;
                    font-weight: 400;
                    cursor: pointer;
                    font-size: 16px;
                  }

                  &>select {
                    height: 40px;
                    outline: none;
                    border-radius: 4px;
                    border: 1px solid #D9D9D9;
                    padding-inline: 10px 25px;
                    appearance: none;
                    background: url('../../../public/assets/icons/arrow-dark.svg');
                    background-repeat: no-repeat;
                    background-position: calc(100% - 7.5%) center;
                    background-size: 14px;
                  }

                  & .btn-remove {
                    height: 40px;
                    width: 40px;
                    border: 1px solid rgba(237, 74, 90, 0.7);
                    background-color: hsl(0deg, 0%, 100%);
                    border-radius: 4px;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 0 0 0 3px rgba(237, 74, 90, 0);
                    transition: background-color 0.3s ease, box-shadow 0.3s ease;
                    will-change: background-color, box-shadow;
                    cursor: inherit;

                    &:hover {
                      box-shadow: 0px 0px 5px rgba(237, 74, 90, 0.7);
                      background-color: rgba(237, 74, 90, 0.2);
                    }
                  }

                  & .btn-update {
                    width: max-content;
                    padding-inline: 10px !important;
                    height: 40px;
                    background-color: rgba(255, 165, 0, 0);
                    border-radius: 4px;
                    border: 1px solid rgba(255, 165, 0, 0.7);
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: background-color 0.3s ease, box-shadow 0.3s ease;
                    box-shadow: 0 0 0 3px rgba(255, 165, 0, 0);
                    will-change: background-color, box-shadow;
                    cursor: inherit;

                    &:hover {
                      box-shadow: 0px 0px 5px rgba(255, 165, 0, 0.7);
                      background-color: rgba(255, 165, 0, 0.2);
                    }
                  }
                }
              }
            }
          }
        }

        & .emptyCart {
          height: 450px;
          display: grid;
          place-content: center;

          &>div {
            display: flex;
            align-items: center;

            & span {
              font-weight: 600;
            }
          }
        }

        & .cartItem {
          display: flex;
          gap: 12px;

          & .descriptionContainer {
            display: flex;
            flex-direction: column;
            gap: 15px;
            flex: 1;
          }

          & .imageContainer {
            padding: 5px;
            border: 1px solid lightgray;
            border-radius: 6px;

            &>img {
              object-fit: cover;
            }
          }

          & .actions {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & .quantity {
              display: grid;
              grid-template-columns: repeat(3, 30px);
              border-radius: 6px;
              overflow: hidden;

              & .number {
                display: grid;
                place-content: center;
                background-color: $blue40;
                color: white;
              }

              &>button {
                border: none;
                background-color: $blue40;
                height: 30px;
                cursor: pointer;

                &>span {
                  display: grid;
                  place-content: center;
                }
              }
            }
          }
        }
      }

      & .step__button {
        text-align: right;
        margin-top: 20px;

        & .button {
          background-color: #4762E6;
          color: white;
          border: none;
          border-radius: 8px;
          padding: 13px 40px;
          cursor: pointer;

          &:disabled {
            opacity: .4;
            cursor: auto;
          }
        }
      }
    }
  }

  & .variation {
    height: 100%;
    width: 100%;

    @media (max-width:500px) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    & .variation__description {
      display: flex;
      flex-direction: column;
      gap: 20px;

      & .productInfo {
        padding-bottom: 20px;
        border-bottom: 1px solid $light70;

        & .title {
          color: black;
          font-size: 32px;
        }

        & .attribute-items {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          margin: 20px 0px;

          &>div {
            display: flex;
            align-items: center;
            justify-content: center;

            &>select {
              padding: 10px 30px 10px 15px;
              display: grid;
              grid-gap: 5px;
              border-radius: 6px;
              color: black;
              outline: none;
              appearance: none;
              -webkit-appearance: none;
              background-image: url('../../../public/assets/icons/arrow-dark.svg');
              background-repeat: no-repeat;
              background-size: 16px;
              background-position: calc(100% - 7.5px) center;
              min-height: 52px;
              min-width: max-content !important;
            }
          }
        }
      }

      & .productActions {

        & .actions-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          @media (max-width:500px) {
            flex-direction: column;
            align-items: flex-start;
          }

          & .left {
            display: flex;
            align-items: center;
            gap: 10px;

            @media (max-width:500px) {
              flex-direction: column;
              align-items: flex-start;
            }

            & .quantity {
              display: grid;
              grid-template-columns: repeat(3, 40px);
              align-items: center;
              border-radius: 12px;
              overflow: hidden;
              width: max-content;

              & .number {
                background-color: $blue40;
                color: white;
                height: 40px;
                display: grid;
                place-content: center;
              }

              & .quantityButton {
                background-color: $blue40;
                border: none;
                padding: 0px;
                height: 40px;
                color: white;
                cursor: pointer;

                & .icon {
                  display: grid;
                  place-content: center;
                }
              }
            }

            & h4 {
              margin: 0;
              color: black;
              font-size: 18px;
              font-family: 'Open Sans';
              font-weight: 500;
            }
          }

          & .right {

            & .add-to-cart {
              background-color: $blue40;
              border: none;
              padding: 10px 15px;
              height: 50px;
              width: 120px;
              border-radius: 12px;
              color: white;
              cursor: pointer;

              &.bounce {
                animation: bounce 2s forwards;
                animation-duration: 0.45s;
                animation-timing-function: cubic-bezier(.4, 0, .2, 1);
              }
            }
          }
        }
      }
    }
  }
}

.ant-popover-inner-content {
  width: 100%;

  & img[alt='details'] {
    margin-top: 4px;
  }
}

// UPDATE MODAL

.order-update-modal {
  height: 500px;

  & .content {
    height: auto;
  }

  & .ant-form {
    & .ant-radio-group {
      margin-left: 90px;

      & label.ant-radio-wrapper {
        font-size: 18px;

        & .ant-input.cancel-reason {
          width: 100%;
          margin-left: -25px;
          margin-top: 10px;
          height: 40px;
          font-size: 16px;
          border: 1px solid red;
        }
      }
    }

    & .form-group {
      position: absolute;
      bottom: 30px;
      left: 77px;
    }
  }
}

// RESCHEDULED MODAL 
.order-rescheduled-modal {
  height: 350px;

  & .content {
    height: 300px;
    justify-content: space-between;

    &>form {
      height: 70%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & input {
        font-size: 20px;
      }
    }
  }
}

@keyframes bounce {

  0%,
  100% {
    transform: translate(0, 0)
  }

  10% {
    transform: translate(0, 2px)
  }

  50% {
    transform: translate(0, -7px)
  }

  90% {
    transform: translate(0, 4px)
  }
}